import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    withRouter
} from "react-router-dom";

import { loadMoreProgram } from "../redux/actions/programs";

import { 
    Box,
    Typography 
} from '@mui/material';

import ProgramSection from '../components/sections/programs';

class Program extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };        
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        return (
        <Box component="div" className="show-page" fullwidth>            
            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography>Programs</Typography>
                    </Box>
                    <ProgramSection />
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadMoreProgram: data => dispatch(loadMoreProgram(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Program));