import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    withRouter
} from "react-router-dom";

import { loadMoreArtist } from "../redux/actions/artists";

import { 
    Box, 
    Typography
} from '@mui/material';

import ArtistSection from '../components/sections/artists';

class Artist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artist:[],
            err:null
        };
        
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore(){
        var ctx = this;
        var page = (this.props.artist.length / process.env.REACT_APP_PERPAGE) + 1;
        ctx.props.loadMoreArtist({slug:"all", page:page, perpage: process.env.REACT_APP_PERPAGE})
        // .then((res)=>{    
            // 
        // })
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        return (
        <Box component="div" className="show-page" fullwidth>
            <Box>
                <Box className='archive-container'>
                    <Box className='section-container-header'>
                        <Typography>Artist</Typography>
                    </Box>
                    <ArtistSection />                    
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadMoreArtist: data => dispatch(loadMoreArtist(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Artist));