import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";

import {
    Link,
    withRouter
} from "react-router-dom";

import { logout } from "../redux/actions/users";
import { 
    playRadio, 
    stopAudio,
    resetAudio,
    getNextShow,
    getCurrentShow,
    getStreamStatus
} from "../redux/actions/utils";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Menu from './menu';

import StopIcon from '@mui/icons-material/Stop';
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import NorrmLogoBlack from "../assets/images/norrm-logo-black.png";
// import NorrmLogoWhite from "../assets/images/norrm-logo-white.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastId:-1,
            isLoading: false,
            mobileMenu: false,
            liveStream: false,
            playRadio: false,
            playLabel: "Play",
            streamSrc: "https://listen.norrm.com/default",
            nowPlaying: "Now Playing : Norrm Radio 247 mixes",
            currentShow:null,
            nextShow:null,
            err: null
        };

        this.logout = this.logout.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    logout(){
        let ctx = this;
        this.props.logout().then(function(){
            ctx.props.history.push("/");
        })
    }

    toggleMenu(){
        if(this.state.mobileMenu === false){
            this.setState({mobileMenu:true});
        }else{
            this.setState({mobileMenu:false});
        }
    }

    componentDidMount(){
        this.getStatus();
        setInterval(() => {
            this.getStatus();
        }, 30000);
    }

    getStatus(){
        var ctx = this;
        this.props.getCurrentShow().then(function(e){
            if(e.data !== null){
                if(ctx.state.lastId !== e.data.id){
                    ctx.setState({
                        lastId:e.data.id,
                        currentShow:e.data,
                        liveStream:true,
                        nowPlaying:"Now Playing : "+e.data.title,
                        streamSrc:(e.data.is_stream!==false)?"https://listen.norrm.com/live":"https://listen.norrm.com/stream"
                    }, function(){
                        if(ctx.props.isAudioPlay === "radio"){
                            ctx.props.resetAudio().then((e)=>{
                                let audioPlayer = new Audio();
                                ctx.setState({playLabel:"Loading..."}, function(){
                                    audioPlayer.src = ctx.state.streamSrc;
                                    audioPlayer.addEventListener("canplaythrough", function() {
                                        ctx.props.playRadio({ audioPlayer:audioPlayer });
                                        ctx.setState({playLabel:"Play"});
                                    })
                                });
                            });
                        }
                    });
                }
            }else{
                if(ctx.state.streamSrc !== "https://listen.norrm.com/default"){
                    ctx.setState({
                        currentShow: null,
                        liveStream: false,
                        nowPlaying: "Now Playing : Norrm Radio 247 mixes",
                        streamSrc: "https://listen.norrm.com/default"
                    }, function(){
                        if(ctx.props.isAudioPlay === "radio"){
                            ctx.props.resetAudio().then((e)=>{
                                let audioPlayer = new Audio();
                                ctx.setState({playLabel:"Loading..."}, function(){
                                    audioPlayer.src = ctx.state.streamSrc;
                                    audioPlayer.addEventListener("canplaythrough", function() {
                                        ctx.props.playRadio({ audioPlayer:audioPlayer });
                                        ctx.setState({playLabel:"Play"});
                                    })
                                });
                            });
                        }
                    });
                }
            }
        });

        this.props.getNextShow().then(function(e){
            if(e.data !== null){
                ctx.setState({nextShow:e.data});
            }else{
                ctx.setState({nextShow:null});
            }
        });
    }

    render() {        
        var nextShowContainer = "";
        if(this.state.nextShow !== null){
            nextShowContainer = <Box component="marquee" sx={{maxWidth:"400px", marginRight:"10px"}}>
                <Typography sx={{marginRight:"5px", whiteSpace:"pre"}}>Upcoming Show : {this.state.nextShow.title} | {format(new Date(this.state.nextShow.scheduled_at.replace(/-/g, "/")), "dd / MMMM / yyyy")} | {format(new Date(this.state.nextShow.scheduled_at.replace(/-/g, "/")), "hh:mm aa")} - {format(new Date(this.state.nextShow.scheduled_end.replace(/-/g, "/")), "hh:mm aa")}</Typography>
            </Box>
        }else{
            nextShowContainer = "";
        }

        return (
            <Box className='main-header'>
                <Drawer                    
                    anchor="left"
                    open={this.state.mobileMenu}
                    onClose={(e)=>{
                        this.setState({mobileMenu:false});
                    }}
                >
                    <Menu closeMenu={this.toggleMenu}/>
                </Drawer>
                <Box className="mobile-nav-link">
                    <Button color='dark' onClick={this.toggleMenu}><MenuIcon /></Button>
                </Box>
                <Box className='logo-wrapper'><Link to='/'><img alt="norrm logo" src={NorrmLogoBlack}/></Link></Box>
                <Menu />                
                <Box sx={{ display:"flex", alignItems:"center", justifyContent:"center", padding:"5px", backgroundColor:"#f8c41e", borderBottom:"1px solid #000" }}>
                    {this.state.liveStream !== false?
                    <Box
                        sx={{
                            display: "flex",
                            width: "60px",
                            paddingLeft: "5px",
                            flexWrap: "nowrap",
                            flexDirection: "row",
                            alignItems: "center",
                            alignContent:"center",                            
                            textAlign: "center",
                        }}
                    ><Box className="livestream-indicator"></Box>Live</Box>:""}
                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", padding:"5px", width:"fit-content", marginRight:"10px", textAlign:"center", cursor:"pointer"}}>
                        { this.props.isAudioPlay !== "radio"?<Box sx={{display:"flex", alignItems:"center"}} onClick={(e)=>{
                            console.log("play button");
                            if(this.state.isLoading !== true){
                                let ctx = this;
                                let audioPlayer = new Audio();
                                this.setState({isLoading:true, playLabel: "Loading..."}, function(){
                                    console.log("loading...");

                                    audioPlayer.src = ctx.state.streamSrc;
                                    audioPlayer.addEventListener('error', () => {
                                        alert("Something error, please try again");
                                        ctx.setState({playLabel:"Play", isLoading:false});
                                    });

                                    audioPlayer.addEventListener("canplaythrough", function() {
                                        ctx.props.playRadio({ audioPlayer:audioPlayer });
                                        ctx.setState({playLabel:"Play", isLoading:false});
                                    })
                                    audioPlayer.load();
                                });
                            }
                        }}><PlayArrowIcon sx={{ marginRight:"5px" }}/> {this.state.playLabel}</Box>:
                        <Box sx={{
                            display:"flex", alignItems:"center", justifyContent:"center", 
                        }} onClick={(e)=>{                                
                            this.props.stopAudio();
                        }}>
                        <StopIcon sx={{ marginRight:"5px" }}/> Stop</Box>
                        }
                    </Box>
                    <Box component="marquee" sx={{width:"-webkit-fill-available", marginRight:"10px"}}>
                        {this.state.nowPlaying}
                    </Box>
                    {nextShowContainer}
                </Box>
            </Box>
        )
    }
}

function mapStateToProps(state){
    return{
        isLogin: state.isLogin,
        userData: state.userData,
        isAudioPlay: state.isAudioPlay,
        audioPlayer: state.audioPlayer,
        // isLiveStreaming: state.isLiveStreaming,
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        logout,
        getNextShow,
        getCurrentShow,
        playRadio,
        stopAudio,
        resetAudio,
        getStreamStatus,
    }, dispatch)
}

// const mapStateToProps = state => ({
//     ...state
// });

// function mapDispatchToProps(dispatch) {
//     return {
//         logout
//         //logout: userData => dispatch(logout(userData))
//     };
// }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));