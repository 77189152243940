import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import { 
  Link, 
  NavLink, 
  withRouter 
} from "react-router-dom";

import { getArtistDetails } from "../redux/actions/artists";

import {
  Box,
  Grid,
  Typography,
} from "@mui/material";

import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import AlbumIcon from '@mui/icons-material/Album';

import LoadingSection from '../components/sections/loadings';

class ArtistDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        show: [],
        loaded:false,
        artist: null,
        err: null,
    };

    this.getInitials = this.getInitials.bind(this);
  }

  getInitials(string){
    var names = string.split(' ');
    var initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
      for(let i = 1;i<names.length;i++){
        initials += names[i].substring(0, 1).toUpperCase();
      }
    }
    return initials;
  }

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);

    this.props.getArtistDetails({ slug: this.props.match.params.slug }).then((res) => {
        if (res.code === 200) {
          this.setState({ artist: res.data, loaded:true });
        }
      });
  }

  render() {
    let ShowContainer = "";
    let HeaderContainer = "";
    let shows = this.state.artist?.shows;
    let avatarUrl = "";//process.env.REACT_APP_DEFAULT_AVATAR;
    let initialArtist = "";

    if(shows !== undefined && shows.length !== 0){
      HeaderContainer = <Box className="artist-container-show">
        <Typography sx={{padding:"10px", textAlign:"center", textTransform:"uppercase"}}>Shows</Typography>              
      </Box>;

      ShowContainer = shows.map((item, idx)=>{
          return(<Fragment key={idx}>
                  <Grid component={NavLink} to={"/show/"+item.slug} data-type="artist" data-quick={idx} item className='grid-container-item' sx={{backgroundImage:"url("+encodeURI(item.image.fullurl)+")"}}></Grid>                  
              </Fragment>
          )
      })
    }
    
    if(this.state.loaded !== false){
      if(this.state.artist.image !== null){
        avatarUrl = encodeURI(this.state.artist.image?.fullurl);
      }else{
        initialArtist = this.getInitials(this.state.artist.name).replace(/[^a-zA-Z0-9 ]/g, "");
      }
    }

    return (
      <Box component="div" className="artist-detail-page">
        {(this.state.loaded !== false)?
          (this.state.artist !== null)?
            <Box sx={{ minHeight: "470px" }}>
              <Box className="artist-container">
                <Box className="artist-container-profile noselect" sx={{backgroundImage:"url("+avatarUrl+")"}}>
                  {initialArtist}
                </Box>
                <Box className="artist-container-detail">
                  <Box className="artist-container-information">
                    <Typography sx={{ fontSize: "18px", fontWeight: "800" }}>
                      {this.state.artist.name}
                    </Typography>

                    <Typography sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                      <img
                        alt={this.state.artist.name}
                        style={{ marginRight: "5px" }}
                        src={`https://flagcdn.com/w20/${this.state.artist?.country?.initial.toLowerCase()}.png`}
                      />
                      {this.state.artist.country.name}
                    </Typography>

                    {this.state.artist.description !== null?<Typography sx={{display:"block", marginTop:"20px"}}>
                      {this.state.artist.description || "No Description"}
                    </Typography>:""}

                    <Box className="artist-container-link" sx={{marginTop:"30px", display:"block"}}>
                      <Typography>Links :</Typography>
                      {this.state.artist.weburl !== null?<Link className="artist-profile-link" to={{ pathname: this.state.artist.weburl }} target="_blank" rel="noopener noreferrer"><LanguageIcon /> Website</Link>:""}
                      {this.state.artist.instagramurl !== null?<Link className="artist-profile-link" to={{ pathname: this.state.artist.instagramurl }} target="_blank" rel="noopener noreferrer"><InstagramIcon /> Instagram</Link>:""}
                      {this.state.artist.mixcloudurl !== null?<Link className="artist-profile-link" to={{ pathname: this.state.artist.mixcloudurl }} target="_blank" rel="noopener noreferrer"><AlbumIcon /> Mixcloud</Link>:""}
                      {this.state.artist.soundcloudurl !== null?<Link className="artist-profile-link" to={{ pathname: this.state.artist.soundcloudurl }} target="_blank" rel="noopener noreferrer"><AlbumIcon /> Soundcloud</Link>:""}
                    </Box>

                  </Box>
                </Box>
              </Box>
              
              {HeaderContainer}

              <Grid container spacing={0} className="grid-container">
                {ShowContainer}
              </Grid>

            </Box>
          :"Artist Not Found"
        :<LoadingSection />}
      </Box>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getArtistDetails: (data) => dispatch(getArtistDetails(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistDetail)
);
