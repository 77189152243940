import React, { Component } from 'react';
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import {
    Link,
    withRouter
} from "react-router-dom";

import { getPress, loadMorePress } from "../redux/actions/presses";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import AlbumIcon from '@mui/icons-material/Album';

import { 
    Box,
    Typography 
} from '@mui/material';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    componentDidMount(){
        ReactGA.pageview(this.props.location.pathname);
    }

    render() {
        return (
        <Box component="div" className="about-page" fullwidth>
            <Box sx={{paddingTop:"50px", paddingBottom:"50px"}}>
                {/* 
                <Typography className="about-text-p" sx={{marginTop:"20px", fontWeight:"800"}}>
                    Business Inquiry :
                </Typography>
                <Typography className="about-text-p" sx={{fontSize:"14px"}} component={Link} to={{ pathname: "tel:+6282130273375" }} target="_blank" rel="noopener noreferrer">Phone</Typography>
                <Typography className="about-text-p" sx={{fontSize:"14px"}} component={Link} to={{ pathname: "mailto:radio@norrm.com" }} target="_blank" rel="noopener noreferrer">Email</Typography> 
                */}

                <Box className='about-container' 
                    sx={{ minHeight:{md:"200px", xs:"300px"}, 
                        display:"flex", 
                        justifyContent:"center", 
                        alignItems:"flex-start",
                        padding:"10px",
                        flexWrap: "wrap",
                        flexDirection: "row",
                        alignContent: "center"
                    }}
                >
                    <Box  className="about-box-left">
                        <Typography className="about-text-head">
                            About<br/>
                            Norrm
                        </Typography>
                        <Typography className="about-text-subhead">
                            Norrm is a group of broadcaster and publisher that collaborates with friends, families, and you.
                        </Typography>
                        <Typography className="about-text-p">
                            We exists to explore, curate, communicate, and define our contemporary alternative culture and identity.
                        </Typography>
                    </Box>
                    <Box className="about-box-right">
                        <Typography className="about-text-p">
                        Founded in 2013, headquartered in Bandung, West Java, Indonesia, Norrm exists as alternative media platform with the direction from Ardo Ardhana, whose vision was to bridge the gap between art, music, architecture, film, and photography under one vertical. Starting from the premise that art and culture are essential to the future vitality of the community, Norrm provides a forum for artist, designer, and creatives talent in the community, to bring their narratives to the public. From years of development, in 2017, Norrm started an online radio broadcasting with Taufik Nofrizal and Egi Purwana, containg documentation of program and institutional history on the form of images, video, audio, along with the publication, printed matter, and programmed experiences.
                        </Typography>
                    </Box>
                </Box>
                <Box className='about-container' 
                sx={{ minHeight:{md:"200px", xs:"300px"}, 
                    display:"flex", 
                    justifyContent:"center", 
                    alignItems:"flex-start",
                    padding:"10px",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignContent: "center"
                }}>
                    <Box  className="about-box-left">
                        <Typography className="about-text-head">
                            Norrm<br/>
                            Radio
                        </Typography>
                    </Box>
                    <Box className="about-box-right">
                        <Typography className="about-text-p">
                        Floating up in the air since 2017, Norrm Radio starts their first radio show with #NR001 Some Velvet Morning with Ayuy on the February 12th, and has never stop airing until this current time. More than 450 shows have been thrown up high airing, local and international, narrowing down the generation gap with the vast music selection.
                        </Typography>
                        <Typography className="about-text-p">
                        Norrm Radio marked as a platform to spin, tune, and mix the records together with people everywhere surpass nation borders, based in Bandung, Indonesia. For us, collaboration is a conversation, leads us to the everlasting connection that grow into a family bound.
                        </Typography>
                    </Box>
                </Box>
                <Box className='about-container'
                sx={{ minHeight:{md:"200px", xs:"300px"}, 
                    display:"flex", 
                    justifyContent:"center", 
                    alignItems:"flex-start",
                    padding:"10px",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignContent: "center"
                }}>
                    <Box  className="about-box-left">
                        <Typography className="about-text-head">
                            Norrm<br/>
                            Press
                        </Typography>
                    </Box>
                    <Box className="about-box-right">
                        <Typography className="about-text-p">
                        Norrm Press is a micro publishing house that focuses in archiving the scattered informations amongst the subcommunity, –bring the subculture’s noises into ordered sentences. Is now still in the process of its development, the relatable alternative contents will be, soon, published both digitally and traditionally.
                        </Typography>
                        <Typography className="about-text-p">
                        Norrm Press is a channel to explore backwards and forwards.
                        </Typography>
                    </Box>
                </Box>
                <Box className='about-container'
                sx={{ minHeight:{md:"200px", xs:"300px"}, 
                    display:"flex", 
                    justifyContent:"center", 
                    alignItems:"flex-start",
                    padding:"10px",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignContent: "center"
                }}>
                    <Box  className="about-box-left">
                        <Typography className="about-text-head">
                            Norrm<br/>
                            Shop
                        </Typography>
                    </Box>
                    <Box className="about-box-right">
                        <Typography className="about-text-p">
                        As a local merch grocery shop, Norrm Shop offers a range of products, from day-to-day objects, outprints, and music miscellaneous. It’s a one stop shopping destination, a quick takeaway before getting into one's shopping bag. Appears to be available for both online and offline grocery shopping experience.
                        </Typography>
                        <Typography className="about-text-p">
                        Shop online through shop.norrm.com or visit the store at Grammars,<br/>
                        Jl. Cihapit no.6, Bandung
                        </Typography>
                    </Box>
                </Box>                
                <Box className='about-container'
                sx={{ minHeight:{md:"200px", xs:"300px"}, 
                    display:"flex", 
                    justifyContent:"center", 
                    alignItems:"flex-start",
                    padding:"10px",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    alignContent: "center"
                }}>
                    <Box  className="about-box-left">
                        <Typography className="about-text-head">
                        Channels &<br />
                        Contacts
                        </Typography>
                    </Box>
                    <Box className="about-box-right">
                        <Typography className="about-text-p" sx={{display:"flex", alignContent:"center", alignItems:"center", fontSize:"16px"}} component={Link} to={{ pathname: "https://instagram.com/norrm" }} target="_blank" rel="noopener noreferrer"> <InstagramIcon sx={{marginRight:"10px"}}/> instagram.com/norrm</Typography>
                        <Typography className="about-text-p" sx={{display:"flex", alignContent:"center", alignItems:"center", fontSize:"16px"}} component={Link} to={{ pathname: "https://facebook.com/norrmcom" }} target="_blank" rel="noopener noreferrer"> <FacebookIcon sx={{marginRight:"10px"}}/> norrmcom</Typography>
                        <Typography className="about-text-p" sx={{display:"flex", alignContent:"center", alignItems:"center", fontSize:"16px"}} component={Link} to={{ pathname: "https://mixcloud.com/norrm" }} target="_blank" rel="noopener noreferrer"> <AlbumIcon sx={{marginRight:"10px"}}/> mixcloud.com/norrm</Typography>
                        <br />
                        Email     :	<Typography className="about-text-p" sx={{fontSize:"14px"}} component={Link} to={{ pathname: "mailto:radio@norrm.com" }} target="_blank" rel="noopener noreferrer">radio@norrm.com</Typography>
                        Phone     :	<Typography className="about-text-p" sx={{fontSize:"14px"}} component={Link} to={{ pathname: "tel:+628122380324" }} target="_blank" rel="noopener noreferrer">+62 812-2380-324</Typography>
                        <br />
                        <Typography className="about-text-p">
                        Cihapit No. 6 - Grammars<br />
                        Bandung 40122, IDN
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPress: data => dispatch(getPress(data)),
        loadMorePress: data => dispatch(loadMorePress(data)),
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About));